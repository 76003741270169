<template>
  <div class="my-auto">
    <form class="form mx-auto col-4" @submit.prevent>
      <h1 class="h3 mb-3 font-weight-normal">Ustawienia</h1>
      <div class="row">
        <div class="col-12">

          <input
            v-if="userProfile"
            id="name"
            class="form-control"
            v-model.trim="name"
            type="text"
            :placeholder="userProfile.name"
          />
          <input
            v-else
           
            class="form-control"
            v-model.trim="name"
            type="text"
            placeholder="Imię i nazwisko"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-6 justify-content-center">
          <button @click="updateProfile()" class="btn w-100 btn-success mt-1">
            Uaktualnij profil
          </button>
        </div>
        <div class="col-6 justify-content-center">
          <button class="btn w-100 btn-warning mt-1" @click="logout()">
            Wyloguj
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      name: "",
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    updateProfile() {
      console.log("tet");
      this.$store.dispatch("updateProfile", {
        name: this.name !== "" ? this.name : this.userProfile.name,
      });
    },
  },

  computed: {
    ...mapState(["userProfile"]),
  },
};
</script>